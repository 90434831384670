'use client'
import { Grid } from '@chakra-ui/react'
import TeamCard from '@components/TeamCard/TeamCard'
import { MotionGridItem } from '@components/motion'
import type {
  DragEndEvent
} from '@dnd-kit/core'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'


import type { getTeams } from '@hooks/teams'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'

interface TeamsGridProps {
  teams: NonNullable<Awaited<ReturnType<typeof getTeams>>>
  draggable?: boolean
}

const TeamsGrid: React.FC<TeamsGridProps> = ({ teams, draggable }) => {
  const [items, setItems] = useState(teams);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    
    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over?.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }


  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <Grid templateColumns="repeat(auto-fill, minmax(450px, 1fr))" gap="2">
          <AnimatePresence initial={false} mode="popLayout">
            {items.map(team => (
              <MotionGridItem
                layout
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                // @ts-expect-error - type overlap
                transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                key={team.id}
              >
                <TeamCard href={`/dashboard/teams/${team.slug}`} key={team.id} team={team} draggable={draggable}/>
              </MotionGridItem>
            ))}
          </AnimatePresence>
        </Grid>
      </SortableContext>
    </DndContext>
  )
}

export default TeamsGrid
