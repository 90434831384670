'use client'
import { Flex } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { Motion } from './motion'

const FlyoutButton: React.FC<PropsWithChildren<{ leftIcon?: JSX.Element, rightIcon?: JSX.Element, onClick?: () => void }>> = ({ leftIcon, rightIcon, children, onClick }) => {
  const [hovering, setHovering] = useState(false)

  return (
    <Motion.Button
      onClick={onClick}
      display="inline-flex"
      animate={hovering ? 'enter' : 'exit'}
      onMouseEnter={() => { setHovering(true) }}
      onMouseLeave={() => { setHovering(false) }}
      variant="default"
      variants={{
        enter: {
          width: '156px',
        },
        exit: {
          width: '40px',
        },
      }}
    >
      <Flex align="center">
        {leftIcon}
        <AnimatePresence>
          {hovering && (
            <Motion.Text
              ml="3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              mr="3"
            >
              {children}
            </Motion.Text>
          )}
          {rightIcon}
        </AnimatePresence>
      </Flex>
    </Motion.Button>
  )
}

export default FlyoutButton
