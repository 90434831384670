'use client'
import type { ChakraComponent } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'
import type { ImageProps as NextImageProps } from 'next/image'
import NextImage from 'next/image'
export const Image: ChakraComponent<'img', NextImageProps> = chakra(NextImage, {
  shouldForwardProp: (prop) =>
    [
      'src',
      'alt',
      'width',
      'height',
      'fill',
      'loader',
      'quality',
      'priority',
      'loading',
      'placeholder',
      'blurDataURL',
      'unoptimized',
      'onLoadingComplete',
    ].includes(prop),
})
