import type { ComponentStyleConfig, ThemeOverride } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import Color from 'color'

function getShades (color: string) {
  return {
    50: Color(color).lighten(0.5).hex(),
    100: Color(color).lighten(0.4).hex(),
    200: Color(color).lighten(0.3).hex(),
    300: Color(color).lighten(0.2).hex(),
    400: Color(color).lighten(0.1).hex(),
    500: color,
    600: Color(color).darken(0.1).hex(),
    700: Color(color).darken(0.2).hex(),
    800: Color(color).darken(0.3).hex(),
    900: Color(color).darken(0.4).hex(),
  }
}

const breakpoints: ThemeOverride['breakpoints'] = {
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
}

const Button: ComponentStyleConfig = {
  variants: {
    default: {
      backgroundColor: 'white',
      borderRadius: 20,
      boxShadow: `2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      100px 100px 80px rgba(0, 0, 0, 0.07)`,
      _hover: {
        color: 'white',
        backgroundColor: 'blue.500',
      },
    },
  },
}
const IconButton: ComponentStyleConfig = {
  variants: {
    default: {
      backgroundColor: 'white',
      borderRadius: 20,
      boxShadow: `2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      100px 100px 80px rgba(0, 0, 0, 0.07)`,
      _hover: {
        color: 'white',
        backgroundColor: 'blue.500',
      },
    },
  },
}

const overrides: ThemeOverride = {
  colors: {
    blue: getShades('#0066FF'),
    purple: getShades('#6600FF'),
  },
  components: {
    Button,
    IconButton,
  },
  styles: {
    global: {
      'html, body': {
        backgroundColor: '#e4e9ed',
      },
    },
  },
  // semanticTokens: {
  //   colors: {
  //     text: {
  //       default: '#16161D',
  //       _dark: '#ade3b8',
  //     },
  //     heroGradientStart: {
  //       default: '#7928CA',
  //       _dark: '#e3a7f9',
  //     },
  //     heroGradientEnd: {
  //       default: '#FF0080',
  //       _dark: '#fbec8f',
  //     },
  //   },
  //   radii: {
  //     button: '12px',
  //   },
  // },
  // colors: {
  //   black: '#16161D',
  // },
  breakpoints,
}

const theme = extendTheme(overrides)

export default theme
