'use client'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Link } from '@chakra-ui/next-js'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { MotionBox } from '@components/motion'
import type { NavItemStoryblok } from '@lib/storyblok.types'
import type React from 'react'

interface NavItemProps {
  blok: NavItemStoryblok
  onOpen?: (activeLink: string) => void
  isActive?: boolean
}
const NavItem: React.FC<NavItemProps> = ({ blok, onOpen, isActive }) => {
  return (
    <Box>
      {blok.href ? ( 
        <Link
          onMouseEnter={() => { onOpen?.(blok._uid) }}
          _hover={{ textDecoration: 'none' }}
          href={blok.href.cached_url ? `/${blok.href.cached_url}` : blok.href.url ?? '#'}
        >
          <Flex>
            {blok.name}
            {blok.children && blok.children.length > 0 && (
              <MotionBox
                initial={{ transform: 'rotate(0deg)' }}
                animate={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }}
                transformOrigin="center"
                ml="1"
              // @ts-expect-error type overlap
                transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              >
                <Icon as={ChevronDownIcon}/>
              </MotionBox>
             )}
          </Flex>
        </Link>
      ) : (
        <Text>{blok.name}</Text>
      )}
    </Box>
  )
}

export default NavItem
