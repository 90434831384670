'use client'
import { Link } from '@chakra-ui/next-js'
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react'
import type { NavLinkStoryblok } from '@lib/storyblok.types'
import { IconlyProfile } from 'assets/Iconly-Profile-Icon'
import { IconlySports } from 'assets/Iconly-Sports-Icon'
import { Iconlyuser } from 'assets/Iconly-user-Icon'
import type React from 'react'
import { BsChevronRight } from "react-icons/bs"


interface NavLinkProps {
  blok: NavLinkStoryblok
}
const NavLink: React.FC<NavLinkProps> = ({ blok }) => {
  const { colorMode } = useColorMode()
  
  return (
    <Flex 
      w="100%"
    >
      <Link
        w="100%"
        _hover={{ textDecoration: 'none' }}
        href={blok.href.url ?? '#'}
      >
        <Flex
          w="100%"
          gap="3"
        // borderRadius="15px 0 0 15px"
          borderRadius="xl"
          px={3}
          py={2}
          alignItems="center"
          justifyContent="flex-start"
          _hover={{ backgroundColor: 'gray.100' }}
        // borderRadius="xl"
          position="relative"
        >
          <Box>
            {blok.name === 'Vorstand' && <Iconlyuser size={32} color="#f5a524" width={2}/>}
            {blok.name === 'Sportstätte' && <IconlySports size={32} color="#9353d3" width={2}/>}
            {blok.name === 'Gastro' && <IconlyProfile size={32} color="#1063c4" width={2}/>}
            {blok.name === 'Trainer' && <IconlyProfile size={32} color="#17c964" width={2}/>}
          </Box>
          <Box>
            <Text>{blok.name}</Text>
            {
            blok.subtitle && (
              <Text fontSize="small" color={colorMode === 'light' ? 'gray.500' : 'gray.400'}>{blok.subtitle}</Text>
            )
          }
          </Box>
          {(blok.children && blok.children.length > 0) && (
            <BsChevronRight/>
        )}
        </Flex>
      </Link>
      {(blok.children && blok.children.length > 0) && (
        <Flex>
          {blok.children.map((child) => (
            <NavLink blok={child} key={child._uid}/>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default NavLink
