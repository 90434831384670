'use client'
import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { Motion } from '@components/motion'
import dayjs from 'dayjs'
import de from 'dayjs/locale/de'
import type { Variants } from 'framer-motion'
import Link from 'next/link'
// only here so that auto import cleanup doesn't remove import
void de
interface Props {
  game: {
    awayTeamLogo: string
    awayTeamName: string
    awayTeamScore: string
    created_at: string | null
    date: string
    homeTeamLogo: string
    homeTeamName: string
    homeTeamScore: string
    id: number
    type: string
    url: string
  }
}

const variants: Variants = {
  open: {
    scale: 1.1,
    transition: {
      type: 'spring',
      stiffness: 500,
      mass: 1.5,
      damping: 20,
    },
  },
  closed: {
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 20,
    },
  },
}

const contentVariants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const GameCard: React.FC<Props> = ({ game }) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Link href={game.url} target="_blank">
      <Motion.Flex
        onMouseEnter={onToggle}
        onMouseLeave={onToggle}
        flexDir="column"
        bg="white"
        gap="3"
        justify="space-between"
        borderRadius="xl"
        maxW="400px"
        minH="110px"
        p="3"
        layout
        variants={variants}
        animate={isOpen ? 'open' : 'closed'}
      >
        <Motion.Flex fontWeight="bold" justify="center" w="100%" variants={contentVariants}>
          {game.homeTeamName} VS. {game.awayTeamName}
        </Motion.Flex>
        <Flex gap="2" alignItems="center" justifyContent="center">
          <Image src={game.homeTeamLogo} alt={game.homeTeamName} w="40px" h="40px"/>
          <Box fontSize="lg" fontWeight="bold">
            {game.homeTeamScore} : {game.awayTeamScore}
          </Box>
          <Image src={game.awayTeamLogo} alt={game.awayTeamName} w="40px" h="40px"/>
        </Flex>
        <Flex flexDir="column">
          <Text fontSize="sm" color="blackAlpha.600">{dayjs(game.date).locale('de').format('dddd, DD.MM.YYYY HH:mm')}</Text>
          <Text fontSize="sm" color="blackAlpha.600">{game.type}</Text>
        </Flex>
      </Motion.Flex>
    </Link>
  )
}

export default GameCard
