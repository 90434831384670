'use client'
import { Image } from '@chakra-ui/next-js'
import { Box, Flex, usePrevious } from '@chakra-ui/react'
import { MotionBox, MotionFlex } from '@components/motion'
import type { getUser, getUserProfile } from '@hooks/user'
import type { NavigationStoryblok } from '@lib/storyblok.types'
import { AnimatePresence } from 'framer-motion'
import Link from 'next/link'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import AvatarPopover from '../Popover'
import NavItem from './NavItem'
import NavLink from './NavLink'

interface DesktopNavProps {
  blok: NavigationStoryblok
  user: Awaited<ReturnType<typeof getUser>>
  userProfile: Awaited<ReturnType<typeof getUserProfile>>
  logoUrl?: string
}

const DesktopNav: React.FC<DesktopNavProps> = ({ user, userProfile, blok, logoUrl }) => {
  const [activeLink, setActiveLink] = useState<string | null>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isHovering, setIsHovering] = useState(false)
  const [activeLinkLeft, setActiveLinkLeft] = useState(0)
  const prevActiveLinkLeft = usePrevious(activeLinkLeft)
  const navLinkRefs = useRef<Record<string, HTMLDivElement | null>>({})
  const menuContainerRef = useRef<HTMLDivElement | null>(null)
  const leaveTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const onOpen = (link: string, ref: HTMLDivElement | null) => {
    setActiveLink(link)
    if (ref) {
      const rect = ref.getBoundingClientRect()
      setActiveLinkLeft(rect.left)
    }
  }

  const handleMouseEnterParent = () => {
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current)
      leaveTimeout.current = null
    }
    setIsHovering(true)
  }

  const handleMouseLeaveParent = () => {
    leaveTimeout.current = setTimeout(() => {
      if (!menuContainerRef.current?.contains(document.activeElement)) {
        setIsHovering(false)
      }
    }, 100)
  }
  const handleMouseEnterChild = () => {
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current)
      leaveTimeout.current = null
    }
    setIsHovering(true)
  }

  const handleMouseLeaveChild = () => {
    setIsHovering(false)
  }

  return (
    <MotionFlex
      py="3"
      px="10"
      h="100%"
      w="calc(100% - var(--chakra-space-3) * 2)"
      shadow={scrollPosition > 0 ? 'xl' : 'none'}
      justifyContent="space-between"
      align="center"
      initial={{ backgroundColor: 'transparent' }}
      // @ts-expect-error type overlap
      transition={{ duration: 0.2 }}
      animate={{ backgroundColor: scrollPosition > 0 ? '#FFFFFFFF' : '#FFFFFF00' }}
      m="3"
      borderRadius="xl"
      pos="sticky"
      top="3"
      zIndex="10"
    >
      <Flex flexDir="row" alignItems="center" gap="4">
        {logoUrl && (
          <MotionBox
            initial={{ width: '250px', height: '75px' }}
            animate={{ width: scrollPosition > 0 ? '150px' : '250px', height: scrollPosition > 0 ? '40px' : '75px' }}
            pos="relative"
          >
            <Link href="/">
              <Image src={logoUrl} alt="logo" fill/>
            </Link>
          </MotionBox>
        )}
        <Flex
          gap="5"
          pos="relative"
          flexShrink={0}
          flexGrow={1}
          flexBasis={0}
          onMouseEnter={handleMouseEnterParent}
          onMouseLeave={handleMouseLeaveParent}
        >
          {blok.nav_links?.map(link => {
            return (
              <Box
                key={link._uid}
                ref={el => { navLinkRefs.current[link._uid] = el }}
                onMouseEnter={() => { onOpen(link._uid, navLinkRefs.current[link._uid]) }}
                h="100%"
              >
                <NavItem blok={link} isActive={activeLink === link._uid && isHovering} onOpen={() => { onOpen(link._uid, navLinkRefs.current[link._uid]) }}/>
              </Box>
            )
          })}
          {isHovering && (blok.nav_links?.find(link => link._uid === activeLink)?.children?.length ?? 0) > 0 && (
            <MotionBox
              onMouseEnter={handleMouseEnterChild}
              onMouseLeave={handleMouseLeaveChild}
              shadow="xl"
              pos="absolute"
              layout
              layoutId="nav-menu"
              w="fit-content"
              minW="50%"
              whiteSpace="nowrap"
              wordBreak="keep-all"
              top={scrollPosition > 0 ? '53px' : '8'}
              bg="white"
              p="3"
              borderRadius="xl"
              ref={menuContainerRef}
            >
              <Flex overflow="hidden" pos="relative" flexDir="column" gap="1">
                <AnimatePresence mode="popLayout">
                  {blok.nav_links?.find(link => link._uid === activeLink)?.children?.map(child => {
                    return (
                      <MotionBox
                        key={child._uid}
                        initial={(prevActiveLinkLeft - activeLinkLeft) < 0 ? { x: 700 } : { x: -700 }}
                        animate={{ x: 0 }}
                        exit={(prevActiveLinkLeft - activeLinkLeft) >= 0 ? { x: -700 } : { x: 700 }}
                      // @ts-expect-error type overlap
                        transition={{ type: 'spring', duration: 0.3 }}
                      >
                        <NavLink blok={child}/>
                      </MotionBox>
                    )
                  })}
                </AnimatePresence>
              </Flex>
            </MotionBox>
          )}
        </Flex>
      </Flex>
      <AvatarPopover key={userProfile?.user_id} user={user} userProfile={userProfile}/>
    </MotionFlex>
  )
}

export default DesktopNav
