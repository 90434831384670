import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, Spinner, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsFillPersonFill } from 'react-icons/bs'
import { HiLockClosed } from 'react-icons/hi'

import { Motion } from '@components/motion'
import { useRive } from '@rive-app/react-canvas'
import { useQueryClient } from '@tanstack/react-query'
import type { Variants } from 'framer-motion'
import { createClient } from 'utils/supabase/component'

const loaderVariants: Variants = {
  stale: {
    clipPath: 'circle(0% at 50% 50%)',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  loading: {
    clipPath: 'circle(100% at 50% 50%)',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  success: {
    clipPath: 'circle(100% at 50% 50%)',
    transition: {
    },
  },
}

interface LoginPopoverContentProps {
  onLoginAnimationStart?: () => void
  onLoginAnimationEnd?: () => void
  onClose: () => void
}

const LoginPopoverContent: React.FC<LoginPopoverContentProps> = ({ onClose, onLoginAnimationStart, onLoginAnimationEnd }) => {
  const supabase = createClient()
  const qc = useQueryClient()
  const { RiveComponent } = useRive({
    src: '/icons/success.riv',
    autoplay: true,

  })
  const { register, handleSubmit, formState: { errors } } = useForm()
  const onSubmit = async formData => {
    setAnimationState('loading')
    const { error } = await supabase.auth.signInWithPassword({
      email: formData.email,
      password: formData.password,
    })
    if (error) {
      console.error(error)
      setAnimationState('stale')
      return
    }
    qc.invalidateQueries({ predicate: (query) => { return ['user', 'userProfile'].includes(query.queryKey[0] as string) } })
    setAnimationState('success')
  }
  const [animationState, setAnimationState] = useState<'stale' | 'loading' | 'success'>('stale')

  return (
    <PopoverContent
      w="400px"
      onAnimationComplete={state => {
        if (state === 'exit' && animationState === 'success') {
          onLoginAnimationEnd?.()
          setAnimationState('stale')
        }
      }}
      motionProps={{
        variants: {
          enter: {
            clipPath: 'circle(100% at 73% 0%)',
          },
          exit: {
            clipPath: 'circle(0% at 81% 0%)',
          },
        },
      }}
      outline="none"
    >
      <PopoverHeader fontWeight="semibold" fontSize="xl" px="4" py="4">Du bist nicht eingeloggt</PopoverHeader>
      <PopoverArrow/>
      <PopoverCloseButton/>
      <PopoverBody pos="relative" bgImage="url(/waves.svg)" bgRepeat="no-repeat" bgPos="bottom" px="7" py="3">
        <Motion.Box
          onAnimationStart={state => {
            if (state === 'loading') onLoginAnimationStart?.()
          }}
          animate={animationState}
          variants={loaderVariants}
          onAnimationComplete={() => {
            if (animationState === 'success') {
              setTimeout(() => {
                onClose()
              }, 1500)
            }
          }}
          pos="absolute"
          zIndex={1}
          top="0"
          transform="translateX(-50%)"
          left="50%"
          w="100%"
          h="100%"
          backdropFilter="blur(5px)"
        >
          <Flex justify="center" align="center" flexDir="column" w="100%" h="70%">
            {animationState === 'success' && (
              <RiveComponent/>
            )}
            {animationState === 'loading' && (
              <Spinner/>
            )}
            <Text>{animationState === 'success' ? '' : 'Laden...'}</Text>
          </Flex>
        </Motion.Box>
        <Box minH="350px">
          <form autoComplete="on" method="POST" action="/" onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>E-Mail</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                      // eslint-disable-next-line react/no-children-prop
                    children={<BsFillPersonFill fill="var(--chakra-colors-gray-500)" size={25}/>}
                  />
                  <Input {...register('email', { required: true })} variant="filled" type="email"/>
                </InputGroup>
                <FormErrorMessage>Dieses Feld ist erforderlich.</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.password}>
                <FormLabel>Passwort</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                      // eslint-disable-next-line react/no-children-prop
                    children={<HiLockClosed fill="var(--chakra-colors-gray-500)" size={25}/>}
                  />
                  <Input {...register('password', { required: true })} variant="filled" type="password"/>
                </InputGroup>
                <FormErrorMessage>Dieses Feld ist erforderlich.</FormErrorMessage>
              </FormControl>
              <FormControl>
                <Flex justifyContent="flex-end">
                  <Button type="submit">Login</Button>
                </Flex>
              </FormControl>
            </VStack>
          </form>
        </Box>
      </PopoverBody>
    </PopoverContent>
  )
}

export default LoginPopoverContent
