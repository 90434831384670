'use client'
import { Image } from '@tiptap/extension-image'
import { Link } from '@tiptap/extension-link'
import { TextAlign } from '@tiptap/extension-text-align'
import { Underline } from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useMemo } from 'react'
import type { TModule } from './EditorMenu'
import EditorMenu from './EditorMenu'
import EditorMenus from './EditorMenus'




export const editorExtensions = [
  StarterKit,
  Underline,
  Image,
  Link.configure({
    autolink: true,
    linkOnPaste: true,
    openOnClick: false,
    protocols: [
      { scheme: 'tel', optionalSlashes: true },
      { scheme: 'mailto', optionalSlashes: true },
      { scheme: 'http', optionalSlashes: true },
      { scheme: 'https', optionalSlashes: true },
    ],
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'right', 'center', 'justify'],
    defaultAlignment: 'left',
  }),
]

// Need this prop for SSR
interface IEditorProps {
  content?: string
  enabledModules?: TModule[]
  setContent: (content: string) => void
}

export const Editor: React.FC<IEditorProps> = ({ content, enabledModules, setContent }) => {
  const parsedContent = useMemo(() => {
    if (content) {
      try {
        return JSON.parse(content)
      } catch {
        return null
      }
    }
    return null
  }, [content])

  const editor = useEditor({
    extensions: editorExtensions,
    content: parsedContent,
    onUpdate: ({ editor }) => {
      setContent(JSON.stringify(editor.getJSON()))
    },
  })
  return (
    <>
      <EditorMenu editor={editor} enabledModules={enabledModules ?? ['all']} showBorderBottom/>
      <EditorContent editor={editor}/>
      <EditorMenus editor={editor} enabledModules={enabledModules}/>
    </>
    // <EditorProvider
    //   content={parsedContent}
    //   onUpdate={({ editor }) => {
    //     setContent(JSON.stringify(editor.getJSON()))
    //   }}
    //   slotBefore={<EditorMenu enabledModules={enabledModules ?? ['all']} showBorderBottom/>}
    //   extensions={editorExtensions}
    // >
    //   <EditorMenus editor={editor} enabledModules={enabledModules}/>
    // </EditorProvider>
  )
}
