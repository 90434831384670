import { Box } from '@chakra-ui/react'
import type { Editor } from '@tiptap/react'
import EditorMenu from './EditorMenu'

const BubbleMenuContent = ({editor}: {editor: Editor | null}) => {
  return (
    <Box
      borderRadius="lg"
      bg="white"
      boxShadow="0 5px 10px #0000004d"
      px="4"
      py="1"
    >
      <EditorMenu editor={editor} enabledModules={['format', 'link', 'textAlign']}/>
    </Box>
  )
}

export default BubbleMenuContent
