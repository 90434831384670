'use client'
import { Motion } from '@components/motion'
import type { Variants } from 'framer-motion'
import Link from 'next/link'
import { useEffect, useState, type PropsWithChildren } from 'react'
import { FiChevronRight } from 'react-icons/fi'

const variants: Variants = {
  base: {
    width: 0,
    opacity: 0,
  },
  hover: {
    opacity: 1,
    width: 'auto',
  },
}

interface IProps {
  active?: boolean
  href?: string
  onClick?: () => void
}

const Button: React.FC<PropsWithChildren<IProps>> = ({ children, active, href, onClick }) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isActive, setIsActive] = useState(active)
  useEffect(() => {
    setIsActive(active)
  }, [active])

  return (
    <Link href={href ?? ''}>
      <Motion.Button
        onClick={onClick}
        initial={false}
        variant="ghost"
        justifyContent="flex-start"
        w="full"
        onMouseEnter={() => { setIsHovering(true) }}
        onMouseLeave={() => { setIsHovering(false) }}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        color={(isActive || isHovering) ? 'blue.500' : 'gray.600'}
      >
        <Motion.Text
          initial={false}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          animate={(isHovering || isActive) ? 'hover' : 'base'}
          variants={variants}
        >
          <FiChevronRight/>
        </Motion.Text>
        {children}
      </Motion.Button>
    </Link>
  )
}

export default Button
