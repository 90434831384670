import { Box, Flex, Heading, ListItem, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, Text, UnorderedList } from '@chakra-ui/react'
import { Image } from '@components/Image'
import Button from '@components/buttons/Button'
import { Motion } from '@components/motion'
import { createClient } from '@utils/supabase/client'
import { useUserProfile } from 'hooks/user'

const UserControlPopoverContent = () => {
  const { data: userProfile } = useUserProfile()
  const sb = createClient()

  return (
    <PopoverContent
      p="0"
      m="0"
      w="400px"
      motionProps={{
        variants: {
          enter: {
            clipPath: 'circle(130% at 73% 0%)',
            transition: {
              staggerChildren: 0.1,
              delayChildren: 0.1,
            },
          },
          exit: {
            clipPath: 'circle(0% at 81% 0%)',
          },
        },
      }}
      outline="none"
    >
      <PopoverArrow/>
      <PopoverCloseButton/>
      <PopoverBody>
        <Box w="100%" h="150px" overflow="hidden" pos="relative" borderRadius="10px">
          <Image
            alt="Profile Banner"
            overflow="hidden"
            src={userProfile?.banner_url ?? `https://zurrtwhctchxgqloanmt.supabase.co/storage/v1/object/public/default_images/${Math.floor(Math.random() * 11) + 1}.svg`}
            fill
            objectFit="cover"
          />
        </Box>
        <Box pos="relative" w="100%" h="75">
          <Flex justify="center" w="100%" pos="absolute" top="-50%">
            <Image
              border="5px solid white"
              borderRadius="50%"
              alt="Profile Image"
              overflow="hidden"
              src={userProfile?.avatar_url ?? `https://zurrtwhctchxgqloanmt.supabase.co/storage/v1/object/public/default_images/${Math.floor(Math.random() * 11) + 1}.svg`}
              width="75"
              height="75"
              objectFit="cover"
            />
          </Flex>
        </Box>
        <Heading fontSize="xl">Hallo, {userProfile?.first_name}!</Heading>
        <Text fontSize="sm" color="gray.400">Was möchtest du tun?</Text>
        <Flex>
          <UnorderedList m="0">
            <Motion.Flex
              variants={{
                enter: {
                  x: 0,
                  opacity: 1,
                },
                exit: {
                  opacity: 0,
                  x: -20,
                },
              }}
              cursor="pointer"
              userSelect="none"
              as={ListItem}
            >
              <Button href="/dashboard/users">Verwaltungsoberfläche</Button>
            </Motion.Flex>
            <Motion.Flex
              variants={{
                enter: {
                  x: 0,
                  opacity: 1,
                },
                exit: {
                  opacity: 0,
                  x: -20,
                },
              }}
              cursor="pointer"
              userSelect="none"
              as={ListItem}
            >
              <Button>Mein Profil</Button>
            </Motion.Flex>
            <Motion.Flex
              variants={{
                enter: {
                  x: 0,
                  opacity: 1,
                },
                exit: {
                  opacity: 0,
                  x: -20,
                },
              }}
              cursor="pointer"
              userSelect="none"
              as={ListItem}
            >
              <Button onClick={() => { sb.auth.signOut() }}>Ausloggen</Button>
            </Motion.Flex>
          </UnorderedList>
        </Flex>
      </PopoverBody>
    </PopoverContent>
  )
}

export default UserControlPopoverContent
