'use client'
import { Image } from '@chakra-ui/next-js'
import { Button, Flex, Popover, PopoverTrigger, useDisclosure } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { createClient } from '@utils/supabase/client'
import { useUser, useUserProfile, type getUser, type getUserProfile } from 'hooks/user'
import { useEffect } from 'react'
import { BsFillPersonFill } from 'react-icons/bs'
import LoginPopoverContent from './LoginPopoverContent'
import UserControlPopoverContent from './UserControlPopoverContent'

interface AvatarPopoverProps {
  user: Awaited<ReturnType<typeof getUser>>
  userProfile: Awaited<ReturnType<typeof getUserProfile>>
}
const AvatarPopover = ({ user, userProfile }: AvatarPopoverProps) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const supabase = createClient()
  const qc = useQueryClient()
  const { data: userProfileData, error: userProfileError, refetch: userProfileRefetch } = useUserProfile()
  const { data: userData, error: userError, refetch: userRefetch } = useUser()

  useEffect(() => {
    qc.setQueryData(['userProfile'], userProfile)
    qc.setQueryData(['user'], user)
  }, [])

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      setTimeout(async () => {
        await userProfileRefetch()
        await userRefetch()
      }, 0)
    })

    return () => {
      data.subscription.unsubscribe()
    }
  }, [supabase.auth, userProfileRefetch, userRefetch])

  return (
    <Popover onClose={onClose} isOpen={isOpen} onOpen={onOpen} placement="bottom">
      <PopoverTrigger>
        <Button p="0" m="0" variant="ghost" _hover={{ background: 'transparent' }}>
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          {(userProfileData || userProfile)
            ? (
              <Image fill borderRadius="full" bg="white" src={userProfileData?.avatar_url ?? userProfile?.avatar_url ?? ''} alt={`${user?.email}'s Avatar`}/>
              )
            : (
              <Flex p="1" bg="white" w="100%" h="100%" borderRadius="full" justifyContent="center" alignItems="center">
                <BsFillPersonFill size={25} color="black"/>
              </Flex>
              )}
        </Button>
      </PopoverTrigger>
      {userData
        ? (
          <UserControlPopoverContent/>
          )
        : (
          <LoginPopoverContent
            onClose={onClose}
          />
          )}
    </Popover>
  )
}

export default AvatarPopover
