'use client'
import type { AlertProps, BoxProps, ButtonProps, DividerProps, FlexProps, GridItemProps, HeadingProps, IconButtonProps, IconProps, ImageProps, InputProps, ListProps, TextProps } from '@chakra-ui/react'
import { Alert, Box, Button, Divider, Flex, GridItem, Heading, Icon, IconButton, Image, Input, Text, UnorderedList } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export const MotionBox = motion<BoxProps>(Box)
export const MotionFlex = motion<FlexProps>(Flex)
export const MotionText = motion<TextProps>(Text)
export const MotionIconButton = motion<IconButtonProps>(IconButton)
export const MotionButton = motion<ButtonProps>(Button)
export const MotionUnorderedList = motion<ListProps>(UnorderedList)
export const MotionImage = motion<ImageProps>(Image)
export const MotionGridItem = motion<GridItemProps>(GridItem)
export const MotionInput = motion<InputProps>(Input)
export const MotionAlert = motion<AlertProps>(Alert)
export const MotionIcon = motion<IconProps>(Icon)
export const MotionHeading = motion<HeadingProps>(Heading)
export const MotionDivider = motion<DividerProps>(Divider)

export const Motion = {
  Box: MotionBox,
  Text: MotionText,
  IconButton: MotionIconButton,
  Button: MotionButton,
  UnorderedList: MotionUnorderedList,
  Flex: MotionFlex,
  Image: MotionImage,
  GridItem: MotionGridItem,
  Input: MotionInput,
  Alert: MotionAlert,
  Icon: MotionIcon,
  Heading: MotionHeading,
  Divider: MotionDivider,
}
