'use client'
import { AlertIcon, AlertTitle, Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { Image } from '@components/Image'
import { Motion, MotionAlert } from '@components/motion'
import { css } from '@emotion/react'
import TiptapImageExtension from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { generateText } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import NextLink from 'next/link'
import type React from 'react'
import { useMemo } from 'react'
interface Props {
  newsItem: {
    id: number
    created_at: string | null
    title: string | null
    text: string | null
    image: string | null
    slug: string
    status: string
    hashtags: Array<{
      id: number
      hashtag: string | null
    }>
  }
  href: string
}

const NewsCardXL: React.FC<Props> = ({ newsItem, href }) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  const parsedContent = useMemo(() => {
    return generateText(JSON.parse(newsItem.text ?? '{}'), [
      StarterKit,
      Underline,
      TiptapImageExtension,
      Link,
      TextAlign,
    ])
  }, [newsItem.text])

  const variants = {
    open: {
      x: -500,
    },
    closed: {
      x: 0,
    },
  }
  const hashtagVariants = {
    open: {
      x: 0,
    },
    closed: {
      x: 700,
    },
  }
  const overlayVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }
  return (
    <NextLink href={href}>
      <Box
        border={newsItem.status === 'DRAFT' ? '3px dashed' : 'none'}
        borderColor="yellow.500"
        cursor="pointer"
        userSelect="none"
        pos="relative"
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        key={`newsItem-${newsItem.id ?? ''}-${newsItem.created_at ?? ''}`}
        w="100%"
        h="260px"
        borderRadius="xl"
        overflow="hidden"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        {newsItem.status === 'DRAFT' && (
          <MotionAlert
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            status="warning"
            borderRadius="md"
            pos="absolute"
            top={2}
            mx="2"
            w="calc(100% - var(--chakra-space-2) * 2)"
            zIndex={1}
          >
            <AlertIcon/>
            <AlertTitle>Dieser Artikel ist noch nicht veröffentlicht</AlertTitle>
          </MotionAlert>
        )}
        <Image src={newsItem.image ?? ''} alt={newsItem.title ?? ''} fill objectFit="cover" pos="absolute"/>
        <Motion.Box
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          variants={overlayVariants}
          bg="blackAlpha.700"
          pos="absolute"
          bottom="0"
          left="0"
          w="100%"
          h="100%"
          borderRadius="xl"
          p={3}
        >
          <Text color="white" fontWeight="bold" fontSize="1.1em">{newsItem.title}</Text>
          <Text
            color="white"
            css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* number of lines to show */
            line-height: var(--chakra-lineHeights-base);        /* fallback */
            max-height: var(--chakra-lineHeights-base) * 3;       /* fallback */
                  `}
          >{parsedContent}
          </Text>
        </Motion.Box>
        <Motion.Box
          layout
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
          maxW="calc(100% - var(--chakra-space-3) - var(--chakra-space-3))"
          pos="absolute"
          borderRadius={20}
          bottom="3"
          left="3"
          px="2"
          py={1}
          bg="white"
                // wordBreak="keep-all"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {newsItem.title}
        </Motion.Box>
        <Motion.Flex
          gap="2"
          animate={isOpen ? 'open' : 'closed'}
          variants={hashtagVariants}
          pos="absolute"
          bottom={3}
          right={5}
        >
          <Flex
            w="100%"
            h="auto"
            flexDir="column-reverse"
            align="end"
            gap={2}
          >
            {newsItem.hashtags.filter(x => x.hashtag!.length <= 15).map((hashtag) => (
              <Box
                key={hashtag.id}
                borderRadius={20}
                bottom="3"
                left="3"
                px="2"
                py={1}
                bg="white"
              >
                {hashtag.hashtag}
              </Box>
            ))}
          </Flex>
        </Motion.Flex>
      </Box>
    </NextLink>
  )
}

export default NewsCardXL
