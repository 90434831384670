import type { Database } from '@lib/database.types'
import type { SupabaseClient } from '@supabase/supabase-js'
import { useQuery } from '@tanstack/react-query'
import { createClient } from 'utils/supabase/component'

export const getSponsors = async (supabase: SupabaseClient<Database>) => {
  const { data, error } = await supabase.from('sponsors').select('*').order('index', { ascending: true })
  if (error) throw new Error(error.message)
  return data
}

export const useSponsors = () => {
  const supabase = createClient()
  return useQuery({ queryKey: ['sponsors'], queryFn: () => getSponsors(supabase) })
}
